import React from "react"

export default function NotFound() {
  return (
    <idv>
      <h1 align="center" variant="h1">
        Not found
      </h1>
    </idv>
  )
}
